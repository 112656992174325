import React, { useEffect } from 'react'
import Image from 'next/image'
import { signIn } from 'next-auth/react'

const Login = () => {
  useEffect(() => {
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'log_in',
      ecommerce: {
        log_in_type: 'MANUAL',
      },
    })

    signIn('migros-account', { callbackUrl: window.location.origin })
  }, [])
  return (
    <div className="page-container">
      <Image
        className="logo"
        src="/android-chrome-192x192.png"
        alt="goodee logo"
        width={64}
        height={64}
      />
      <style jsx>
        {`
          .page-container {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
          }
        `}
      </style>
    </div>
  )
}

export default Login
